const BASEURL = 'https://pagewide-dev.hp-blueprint.com/';
const BASEAPIURL = 'https://pagewide.hp-blueprint.com/api/';
const EXT = '.php';
// const CMS = 'https://admin-pagewide.hp-blueprint.com/';
const CMS = 'https://admin-staging-pagewide.hp-blueprint.com/';

export {
  BASEURL,
  BASEAPIURL,
  EXT,
  CMS
};
