<template>
  <!-- eslint-disable max-len -->
  <Modal
    class="home--modal js-modal-home-intro"
    idModal="homeIntroModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="homeIntroModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
    ref="homeIntroModal">
    <div class="home--modal__title text-center">
      <h2 class="title" v-html="$t('home-modal.headline')"></h2>
      <p class="subttitle">
        {{ $t('home-modal.description') }}
      </p>
    </div>
    <div class="home--modal__content">
      <div class="row">
        <div class="col-md-7">
          <div class="row no-gutters">
            <div class="col-md-4">
              <div class="home--modal__content-card">
                <div class="home--modal__content-card--text">
                  <p class="eyebrow">
                    {{ $t('home-modal.column-1.eyebrow') }}
                  </p>
                  <!-- <div class="number">
                    <template v-if="$i18n.locale !== 'en'">
                      <svg
                        width="147"
                        height="68"
                        viewBox="0 0 147 68"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_2_175"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="44"
                          height="68">
                          <path
                            d="M22.396 67.584C15.484 67.584 10.14 66.048 6.36402 62.976C2.65202 59.904 0.796021 55.168 0.796021 48.768C0.796021 45.12 1.50002 41.92 2.90802 39.168C4.38002 36.352 6.94002 34.112 10.588 32.448C7.70802 30.976 5.62802 28.896 4.34802 26.208C3.13202 23.52 2.52402 20.64 2.52402 17.568C2.52402 11.936 4.15602 7.616 7.42002 4.608C10.748 1.536 15.708 0 22.3 0C28.892 0 33.82 1.536 37.084 4.608C40.412 7.616 42.076 11.936 42.076 17.568C42.076 20.64 41.436 23.52 40.156 26.208C38.94 28.896 36.892 30.976 34.012 32.448C37.66 34.112 40.188 36.32 41.596 39.072C43.068 41.824 43.804 45.056 43.804 48.768C43.804 55.168 41.948 59.904 38.236 62.976C34.588 66.048 29.308 67.584 22.396 67.584ZM22.3 59.616C26.14 59.616 29.084 58.752 31.132 57.024C33.18 55.232 34.204 52.288 34.204 48.192C34.204 46.592 34.044 45.088 33.724 43.68C33.404 42.208 32.796 40.96 31.9 39.936C31.068 38.912 29.852 38.112 28.252 37.536C26.716 36.896 24.732 36.576 22.3 36.576C19.868 36.576 17.852 36.896 16.252 37.536C14.716 38.112 13.5 38.912 12.604 39.936C11.772 40.96 11.196 42.208 10.876 43.68C10.556 45.088 10.396 46.592 10.396 48.192C10.396 52.288 11.42 55.232 13.468 57.024C15.516 58.752 18.46 59.616 22.3 59.616ZM22.3 28.8C24.604 28.8 26.46 28.512 27.868 27.936C29.276 27.296 30.332 26.496 31.036 25.536C31.804 24.512 32.284 23.36 32.476 22.08C32.732 20.736 32.86 19.392 32.86 18.048C32.86 14.464 31.996 11.84 30.268 10.176C28.604 8.512 25.948 7.68 22.3 7.68C18.652 7.68 15.964 8.512 14.236 10.176C12.572 11.84 11.74 14.464 11.74 18.048C11.74 19.392 11.836 20.736 12.028 22.08C12.284 23.36 12.764 24.512 13.468 25.536C14.236 26.496 15.324 27.296 16.732 27.936C18.14 28.512 19.996 28.8 22.3 28.8Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask0_2_175)">
                          <path
                            d="M22.396 67.584C15.484 67.584 10.14 66.048 6.36402 62.976C2.65202 59.904 0.796021 55.168 0.796021 48.768C0.796021 45.12 1.50002 41.92 2.90802 39.168C4.38002 36.352 6.94002 34.112 10.588 32.448C7.70802 30.976 5.62802 28.896 4.34802 26.208C3.13202 23.52 2.52402 20.64 2.52402 17.568C2.52402 11.936 4.15602 7.616 7.42002 4.608C10.748 1.536 15.708 0 22.3 0C28.892 0 33.82 1.536 37.084 4.608C40.412 7.616 42.076 11.936 42.076 17.568C42.076 20.64 41.436 23.52 40.156 26.208C38.94 28.896 36.892 30.976 34.012 32.448C37.66 34.112 40.188 36.32 41.596 39.072C43.068 41.824 43.804 45.056 43.804 48.768C43.804 55.168 41.948 59.904 38.236 62.976C34.588 66.048 29.308 67.584 22.396 67.584ZM22.3 59.616C26.14 59.616 29.084 58.752 31.132 57.024C33.18 55.232 34.204 52.288 34.204 48.192C34.204 46.592 34.044 45.088 33.724 43.68C33.404 42.208 32.796 40.96 31.9 39.936C31.068 38.912 29.852 38.112 28.252 37.536C26.716 36.896 24.732 36.576 22.3 36.576C19.868 36.576 17.852 36.896 16.252 37.536C14.716 38.112 13.5 38.912 12.604 39.936C11.772 40.96 11.196 42.208 10.876 43.68C10.556 45.088 10.396 46.592 10.396 48.192C10.396 52.288 11.42 55.232 13.468 57.024C15.516 58.752 18.46 59.616 22.3 59.616ZM22.3 28.8C24.604 28.8 26.46 28.512 27.868 27.936C29.276 27.296 30.332 26.496 31.036 25.536C31.804 24.512 32.284 23.36 32.476 22.08C32.732 20.736 32.86 19.392 32.86 18.048C32.86 14.464 31.996 11.84 30.268 10.176C28.604 8.512 25.948 7.68 22.3 7.68C18.652 7.68 15.964 8.512 14.236 10.176C12.572 11.84 11.74 14.464 11.74 18.048C11.74 19.392 11.836 20.736 12.028 22.08C12.284 23.36 12.764 24.512 13.468 25.536C14.236 26.496 15.324 27.296 16.732 27.936C18.14 28.512 19.996 28.8 22.3 28.8Z"
                            fill="white" />
                          <path
                            class="number--mask"
                            d="M39.7751 -8.47198H123.775L166.775 78.528H82.7751L39.7751 -8.47198Z"
                            fill="#56BEF9" />
                        </g>
                        <mask
                          id="mask1_2_175"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="51"
                          y="0"
                          width="45"
                          height="68">
                          <path
                            d="M61.7881 33.792C61.7881 38.528 61.9481 42.528 62.2681 45.792C62.5881 49.056 63.1641 51.712 63.9961 53.76C64.8921 55.744 66.0761 57.184 67.5481 58.08C69.0841 58.912 71.0361 59.328 73.4041 59.328C75.7721 59.328 77.6921 58.912 79.1641 58.08C80.7001 57.184 81.8841 55.744 82.7161 53.76C83.6121 51.712 84.2201 49.056 84.5401 45.792C84.8601 42.528 85.0201 38.496 85.0201 33.696C85.0201 28.96 84.8601 24.96 84.5401 21.696C84.2201 18.432 83.6121 15.808 82.7161 13.824C81.8841 11.84 80.7001 10.432 79.1641 9.6C77.6921 8.704 75.7721 8.256 73.4041 8.256C71.0361 8.256 69.0841 8.704 67.5481 9.6C66.0761 10.432 64.8921 11.872 63.9961 13.92C63.1641 15.904 62.5881 18.528 62.2681 21.792C61.9481 24.992 61.7881 28.992 61.7881 33.792ZM51.8041 33.792C51.8041 27.008 52.2841 21.44 53.2441 17.088C54.2681 12.672 55.7081 9.216 57.5641 6.72C59.4841 4.16 61.7561 2.4 64.3801 1.44C67.0681 0.48 70.0761 0 73.4041 0C76.7321 0 79.7081 0.48 82.3321 1.44C85.0201 2.336 87.2921 4.032 89.1481 6.528C91.0041 9.024 92.4441 12.48 93.4681 16.896C94.4921 21.248 95.0041 26.88 95.0041 33.792C95.0041 40.704 94.4921 46.368 93.4681 50.784C92.4441 55.136 91.0041 58.56 89.1481 61.056C87.2921 63.552 85.0201 65.28 82.3321 66.24C79.7081 67.136 76.7321 67.584 73.4041 67.584C70.0761 67.584 67.0681 67.136 64.3801 66.24C61.7561 65.28 59.4841 63.552 57.5641 61.056C55.7081 58.56 54.2681 55.136 53.2441 50.784C52.2841 46.368 51.8041 40.704 51.8041 33.792Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask1_2_175)">
                          <path
                            d="M61.7881 33.792C61.7881 38.528 61.9481 42.528 62.2681 45.792C62.5881 49.056 63.1641 51.712 63.9961 53.76C64.8921 55.744 66.0761 57.184 67.5481 58.08C69.0841 58.912 71.0361 59.328 73.4041 59.328C75.7721 59.328 77.6921 58.912 79.1641 58.08C80.7001 57.184 81.8841 55.744 82.7161 53.76C83.6121 51.712 84.2201 49.056 84.5401 45.792C84.8601 42.528 85.0201 38.496 85.0201 33.696C85.0201 28.96 84.8601 24.96 84.5401 21.696C84.2201 18.432 83.6121 15.808 82.7161 13.824C81.8841 11.84 80.7001 10.432 79.1641 9.6C77.6921 8.704 75.7721 8.256 73.4041 8.256C71.0361 8.256 69.0841 8.704 67.5481 9.6C66.0761 10.432 64.8921 11.872 63.9961 13.92C63.1641 15.904 62.5881 18.528 62.2681 21.792C61.9481 24.992 61.7881 28.992 61.7881 33.792ZM51.8041 33.792C51.8041 27.008 52.2841 21.44 53.2441 17.088C54.2681 12.672 55.7081 9.216 57.5641 6.72C59.4841 4.16 61.7561 2.4 64.3801 1.44C67.0681 0.48 70.0761 0 73.4041 0C76.7321 0 79.7081 0.48 82.3321 1.44C85.0201 2.336 87.2921 4.032 89.1481 6.528C91.0041 9.024 92.4441 12.48 93.4681 16.896C94.4921 21.248 95.0041 26.88 95.0041 33.792C95.0041 40.704 94.4921 46.368 93.4681 50.784C92.4441 55.136 91.0041 58.56 89.1481 61.056C87.2921 63.552 85.0201 65.28 82.3321 66.24C79.7081 67.136 76.7321 67.584 73.4041 67.584C70.0761 67.584 67.0681 67.136 64.3801 66.24C61.7561 65.28 59.4841 63.552 57.5641 61.056C55.7081 58.56 54.2681 55.136 53.2441 50.784C52.2841 46.368 51.8041 40.704 51.8041 33.792Z"
                            fill="white" />
                          <path
                            class="number--mask"
                            d="M90.7751 -8.47198H174.775L217.775 78.528H133.775L90.7751 -8.47198Z"
                            fill="#EA39C7" />
                        </g>
                        <mask
                          id="mask2_2_175"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="103"
                          y="0"
                          width="44"
                          height="68">
                          <path
                            d="M112.988 33.792C112.988 38.528 113.148 42.528 113.468 45.792C113.788 49.056 114.364 51.712 115.196 53.76C116.092 55.744 117.276 57.184 118.748 58.08C120.284 58.912 122.236 59.328 124.604 59.328C126.972 59.328 128.892 58.912 130.364 58.08C131.9 57.184 133.084 55.744 133.916 53.76C134.812 51.712 135.42 49.056 135.74 45.792C136.06 42.528 136.22 38.496 136.22 33.696C136.22 28.96 136.06 24.96 135.74 21.696C135.42 18.432 134.812 15.808 133.916 13.824C133.084 11.84 131.9 10.432 130.364 9.6C128.892 8.704 126.972 8.256 124.604 8.256C122.236 8.256 120.284 8.704 118.748 9.6C117.276 10.432 116.092 11.872 115.196 13.92C114.364 15.904 113.788 18.528 113.468 21.792C113.148 24.992 112.988 28.992 112.988 33.792ZM103.004 33.792C103.004 27.008 103.484 21.44 104.444 17.088C105.468 12.672 106.908 9.216 108.764 6.72C110.684 4.16 112.956 2.4 115.58 1.44C118.268 0.48 121.276 0 124.604 0C127.932 0 130.908 0.48 133.532 1.44C136.22 2.336 138.492 4.032 140.348 6.528C142.204 9.024 143.644 12.48 144.668 16.896C145.692 21.248 146.204 26.88 146.204 33.792C146.204 40.704 145.692 46.368 144.668 50.784C143.644 55.136 142.204 58.56 140.348 61.056C138.492 63.552 136.22 65.28 133.532 66.24C130.908 67.136 127.932 67.584 124.604 67.584C121.276 67.584 118.268 67.136 115.58 66.24C112.956 65.28 110.684 63.552 108.764 61.056C106.908 58.56 105.468 55.136 104.444 50.784C103.484 46.368 103.004 40.704 103.004 33.792Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask2_2_175)">
                          <path
                            d="M112.988 33.792C112.988 38.528 113.148 42.528 113.468 45.792C113.788 49.056 114.364 51.712 115.196 53.76C116.092 55.744 117.276 57.184 118.748 58.08C120.284 58.912 122.236 59.328 124.604 59.328C126.972 59.328 128.892 58.912 130.364 58.08C131.9 57.184 133.084 55.744 133.916 53.76C134.812 51.712 135.42 49.056 135.74 45.792C136.06 42.528 136.22 38.496 136.22 33.696C136.22 28.96 136.06 24.96 135.74 21.696C135.42 18.432 134.812 15.808 133.916 13.824C133.084 11.84 131.9 10.432 130.364 9.6C128.892 8.704 126.972 8.256 124.604 8.256C122.236 8.256 120.284 8.704 118.748 9.6C117.276 10.432 116.092 11.872 115.196 13.92C114.364 15.904 113.788 18.528 113.468 21.792C113.148 24.992 112.988 28.992 112.988 33.792ZM103.004 33.792C103.004 27.008 103.484 21.44 104.444 17.088C105.468 12.672 106.908 9.216 108.764 6.72C110.684 4.16 112.956 2.4 115.58 1.44C118.268 0.48 121.276 0 124.604 0C127.932 0 130.908 0.48 133.532 1.44C136.22 2.336 138.492 4.032 140.348 6.528C142.204 9.024 143.644 12.48 144.668 16.896C145.692 21.248 146.204 26.88 146.204 33.792C146.204 40.704 145.692 46.368 144.668 50.784C143.644 55.136 142.204 58.56 140.348 61.056C138.492 63.552 136.22 65.28 133.532 66.24C130.908 67.136 127.932 67.584 124.604 67.584C121.276 67.584 118.268 67.136 115.58 66.24C112.956 65.28 110.684 63.552 108.764 61.056C106.908 58.56 105.468 55.136 104.444 50.784C103.484 46.368 103.004 40.704 103.004 33.792Z"
                            fill="white" />
                          <path
                            class="number--mask"
                            d="M141.775 -8.47198H225.775L268.775 78.528H184.775L141.775 -8.47198Z"
                            fill="#FDF050" />
                        </g>
                      </svg>
                    </template>
                    <template v-else-if="$i18n.locale !== 'de'">
                      <svg
                        width="147"
                        height="68"
                        viewBox="0 0 147 68"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_2_175"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="44"
                          height="68">
                          <path
                            d="M22.396 67.584C15.484 67.584 10.14 66.048 6.36402 62.976C2.65202 59.904 0.796021 55.168 0.796021 48.768C0.796021 45.12 1.50002 41.92 2.90802 39.168C4.38002 36.352 6.94002 34.112 10.588 32.448C7.70802 30.976 5.62802 28.896 4.34802 26.208C3.13202 23.52 2.52402 20.64 2.52402 17.568C2.52402 11.936 4.15602 7.616 7.42002 4.608C10.748 1.536 15.708 0 22.3 0C28.892 0 33.82 1.536 37.084 4.608C40.412 7.616 42.076 11.936 42.076 17.568C42.076 20.64 41.436 23.52 40.156 26.208C38.94 28.896 36.892 30.976 34.012 32.448C37.66 34.112 40.188 36.32 41.596 39.072C43.068 41.824 43.804 45.056 43.804 48.768C43.804 55.168 41.948 59.904 38.236 62.976C34.588 66.048 29.308 67.584 22.396 67.584ZM22.3 59.616C26.14 59.616 29.084 58.752 31.132 57.024C33.18 55.232 34.204 52.288 34.204 48.192C34.204 46.592 34.044 45.088 33.724 43.68C33.404 42.208 32.796 40.96 31.9 39.936C31.068 38.912 29.852 38.112 28.252 37.536C26.716 36.896 24.732 36.576 22.3 36.576C19.868 36.576 17.852 36.896 16.252 37.536C14.716 38.112 13.5 38.912 12.604 39.936C11.772 40.96 11.196 42.208 10.876 43.68C10.556 45.088 10.396 46.592 10.396 48.192C10.396 52.288 11.42 55.232 13.468 57.024C15.516 58.752 18.46 59.616 22.3 59.616ZM22.3 28.8C24.604 28.8 26.46 28.512 27.868 27.936C29.276 27.296 30.332 26.496 31.036 25.536C31.804 24.512 32.284 23.36 32.476 22.08C32.732 20.736 32.86 19.392 32.86 18.048C32.86 14.464 31.996 11.84 30.268 10.176C28.604 8.512 25.948 7.68 22.3 7.68C18.652 7.68 15.964 8.512 14.236 10.176C12.572 11.84 11.74 14.464 11.74 18.048C11.74 19.392 11.836 20.736 12.028 22.08C12.284 23.36 12.764 24.512 13.468 25.536C14.236 26.496 15.324 27.296 16.732 27.936C18.14 28.512 19.996 28.8 22.3 28.8Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask0_2_175)">
                          <path
                            d="M22.396 67.584C15.484 67.584 10.14 66.048 6.36402 62.976C2.65202 59.904 0.796021 55.168 0.796021 48.768C0.796021 45.12 1.50002 41.92 2.90802 39.168C4.38002 36.352 6.94002 34.112 10.588 32.448C7.70802 30.976 5.62802 28.896 4.34802 26.208C3.13202 23.52 2.52402 20.64 2.52402 17.568C2.52402 11.936 4.15602 7.616 7.42002 4.608C10.748 1.536 15.708 0 22.3 0C28.892 0 33.82 1.536 37.084 4.608C40.412 7.616 42.076 11.936 42.076 17.568C42.076 20.64 41.436 23.52 40.156 26.208C38.94 28.896 36.892 30.976 34.012 32.448C37.66 34.112 40.188 36.32 41.596 39.072C43.068 41.824 43.804 45.056 43.804 48.768C43.804 55.168 41.948 59.904 38.236 62.976C34.588 66.048 29.308 67.584 22.396 67.584ZM22.3 59.616C26.14 59.616 29.084 58.752 31.132 57.024C33.18 55.232 34.204 52.288 34.204 48.192C34.204 46.592 34.044 45.088 33.724 43.68C33.404 42.208 32.796 40.96 31.9 39.936C31.068 38.912 29.852 38.112 28.252 37.536C26.716 36.896 24.732 36.576 22.3 36.576C19.868 36.576 17.852 36.896 16.252 37.536C14.716 38.112 13.5 38.912 12.604 39.936C11.772 40.96 11.196 42.208 10.876 43.68C10.556 45.088 10.396 46.592 10.396 48.192C10.396 52.288 11.42 55.232 13.468 57.024C15.516 58.752 18.46 59.616 22.3 59.616ZM22.3 28.8C24.604 28.8 26.46 28.512 27.868 27.936C29.276 27.296 30.332 26.496 31.036 25.536C31.804 24.512 32.284 23.36 32.476 22.08C32.732 20.736 32.86 19.392 32.86 18.048C32.86 14.464 31.996 11.84 30.268 10.176C28.604 8.512 25.948 7.68 22.3 7.68C18.652 7.68 15.964 8.512 14.236 10.176C12.572 11.84 11.74 14.464 11.74 18.048C11.74 19.392 11.836 20.736 12.028 22.08C12.284 23.36 12.764 24.512 13.468 25.536C14.236 26.496 15.324 27.296 16.732 27.936C18.14 28.512 19.996 28.8 22.3 28.8Z"
                            fill="white" />
                          <path
                            class="number--mask"
                            d="M39.7751 -8.47198H123.775L166.775 78.528H82.7751L39.7751 -8.47198Z"
                            fill="#56BEF9" />
                        </g>
                        <mask
                          id="mask1_2_175"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="51"
                          y="0"
                          width="45"
                          height="68">
                          <path
                            d="M61.7881 33.792C61.7881 38.528 61.9481 42.528 62.2681 45.792C62.5881 49.056 63.1641 51.712 63.9961 53.76C64.8921 55.744 66.0761 57.184 67.5481 58.08C69.0841 58.912 71.0361 59.328 73.4041 59.328C75.7721 59.328 77.6921 58.912 79.1641 58.08C80.7001 57.184 81.8841 55.744 82.7161 53.76C83.6121 51.712 84.2201 49.056 84.5401 45.792C84.8601 42.528 85.0201 38.496 85.0201 33.696C85.0201 28.96 84.8601 24.96 84.5401 21.696C84.2201 18.432 83.6121 15.808 82.7161 13.824C81.8841 11.84 80.7001 10.432 79.1641 9.6C77.6921 8.704 75.7721 8.256 73.4041 8.256C71.0361 8.256 69.0841 8.704 67.5481 9.6C66.0761 10.432 64.8921 11.872 63.9961 13.92C63.1641 15.904 62.5881 18.528 62.2681 21.792C61.9481 24.992 61.7881 28.992 61.7881 33.792ZM51.8041 33.792C51.8041 27.008 52.2841 21.44 53.2441 17.088C54.2681 12.672 55.7081 9.216 57.5641 6.72C59.4841 4.16 61.7561 2.4 64.3801 1.44C67.0681 0.48 70.0761 0 73.4041 0C76.7321 0 79.7081 0.48 82.3321 1.44C85.0201 2.336 87.2921 4.032 89.1481 6.528C91.0041 9.024 92.4441 12.48 93.4681 16.896C94.4921 21.248 95.0041 26.88 95.0041 33.792C95.0041 40.704 94.4921 46.368 93.4681 50.784C92.4441 55.136 91.0041 58.56 89.1481 61.056C87.2921 63.552 85.0201 65.28 82.3321 66.24C79.7081 67.136 76.7321 67.584 73.4041 67.584C70.0761 67.584 67.0681 67.136 64.3801 66.24C61.7561 65.28 59.4841 63.552 57.5641 61.056C55.7081 58.56 54.2681 55.136 53.2441 50.784C52.2841 46.368 51.8041 40.704 51.8041 33.792Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask1_2_175)">
                          <path
                            d="M61.7881 33.792C61.7881 38.528 61.9481 42.528 62.2681 45.792C62.5881 49.056 63.1641 51.712 63.9961 53.76C64.8921 55.744 66.0761 57.184 67.5481 58.08C69.0841 58.912 71.0361 59.328 73.4041 59.328C75.7721 59.328 77.6921 58.912 79.1641 58.08C80.7001 57.184 81.8841 55.744 82.7161 53.76C83.6121 51.712 84.2201 49.056 84.5401 45.792C84.8601 42.528 85.0201 38.496 85.0201 33.696C85.0201 28.96 84.8601 24.96 84.5401 21.696C84.2201 18.432 83.6121 15.808 82.7161 13.824C81.8841 11.84 80.7001 10.432 79.1641 9.6C77.6921 8.704 75.7721 8.256 73.4041 8.256C71.0361 8.256 69.0841 8.704 67.5481 9.6C66.0761 10.432 64.8921 11.872 63.9961 13.92C63.1641 15.904 62.5881 18.528 62.2681 21.792C61.9481 24.992 61.7881 28.992 61.7881 33.792ZM51.8041 33.792C51.8041 27.008 52.2841 21.44 53.2441 17.088C54.2681 12.672 55.7081 9.216 57.5641 6.72C59.4841 4.16 61.7561 2.4 64.3801 1.44C67.0681 0.48 70.0761 0 73.4041 0C76.7321 0 79.7081 0.48 82.3321 1.44C85.0201 2.336 87.2921 4.032 89.1481 6.528C91.0041 9.024 92.4441 12.48 93.4681 16.896C94.4921 21.248 95.0041 26.88 95.0041 33.792C95.0041 40.704 94.4921 46.368 93.4681 50.784C92.4441 55.136 91.0041 58.56 89.1481 61.056C87.2921 63.552 85.0201 65.28 82.3321 66.24C79.7081 67.136 76.7321 67.584 73.4041 67.584C70.0761 67.584 67.0681 67.136 64.3801 66.24C61.7561 65.28 59.4841 63.552 57.5641 61.056C55.7081 58.56 54.2681 55.136 53.2441 50.784C52.2841 46.368 51.8041 40.704 51.8041 33.792Z"
                            fill="white" />
                          <path
                            class="number--mask"
                            d="M90.7751 -8.47198H174.775L217.775 78.528H133.775L90.7751 -8.47198Z"
                            fill="#EA39C7" />
                        </g>
                        <mask
                          id="mask2_2_175"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="103"
                          y="0"
                          width="44"
                          height="68">
                          <path
                            d="M112.988 33.792C112.988 38.528 113.148 42.528 113.468 45.792C113.788 49.056 114.364 51.712 115.196 53.76C116.092 55.744 117.276 57.184 118.748 58.08C120.284 58.912 122.236 59.328 124.604 59.328C126.972 59.328 128.892 58.912 130.364 58.08C131.9 57.184 133.084 55.744 133.916 53.76C134.812 51.712 135.42 49.056 135.74 45.792C136.06 42.528 136.22 38.496 136.22 33.696C136.22 28.96 136.06 24.96 135.74 21.696C135.42 18.432 134.812 15.808 133.916 13.824C133.084 11.84 131.9 10.432 130.364 9.6C128.892 8.704 126.972 8.256 124.604 8.256C122.236 8.256 120.284 8.704 118.748 9.6C117.276 10.432 116.092 11.872 115.196 13.92C114.364 15.904 113.788 18.528 113.468 21.792C113.148 24.992 112.988 28.992 112.988 33.792ZM103.004 33.792C103.004 27.008 103.484 21.44 104.444 17.088C105.468 12.672 106.908 9.216 108.764 6.72C110.684 4.16 112.956 2.4 115.58 1.44C118.268 0.48 121.276 0 124.604 0C127.932 0 130.908 0.48 133.532 1.44C136.22 2.336 138.492 4.032 140.348 6.528C142.204 9.024 143.644 12.48 144.668 16.896C145.692 21.248 146.204 26.88 146.204 33.792C146.204 40.704 145.692 46.368 144.668 50.784C143.644 55.136 142.204 58.56 140.348 61.056C138.492 63.552 136.22 65.28 133.532 66.24C130.908 67.136 127.932 67.584 124.604 67.584C121.276 67.584 118.268 67.136 115.58 66.24C112.956 65.28 110.684 63.552 108.764 61.056C106.908 58.56 105.468 55.136 104.444 50.784C103.484 46.368 103.004 40.704 103.004 33.792Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask2_2_175)">
                          <path
                            d="M112.988 33.792C112.988 38.528 113.148 42.528 113.468 45.792C113.788 49.056 114.364 51.712 115.196 53.76C116.092 55.744 117.276 57.184 118.748 58.08C120.284 58.912 122.236 59.328 124.604 59.328C126.972 59.328 128.892 58.912 130.364 58.08C131.9 57.184 133.084 55.744 133.916 53.76C134.812 51.712 135.42 49.056 135.74 45.792C136.06 42.528 136.22 38.496 136.22 33.696C136.22 28.96 136.06 24.96 135.74 21.696C135.42 18.432 134.812 15.808 133.916 13.824C133.084 11.84 131.9 10.432 130.364 9.6C128.892 8.704 126.972 8.256 124.604 8.256C122.236 8.256 120.284 8.704 118.748 9.6C117.276 10.432 116.092 11.872 115.196 13.92C114.364 15.904 113.788 18.528 113.468 21.792C113.148 24.992 112.988 28.992 112.988 33.792ZM103.004 33.792C103.004 27.008 103.484 21.44 104.444 17.088C105.468 12.672 106.908 9.216 108.764 6.72C110.684 4.16 112.956 2.4 115.58 1.44C118.268 0.48 121.276 0 124.604 0C127.932 0 130.908 0.48 133.532 1.44C136.22 2.336 138.492 4.032 140.348 6.528C142.204 9.024 143.644 12.48 144.668 16.896C145.692 21.248 146.204 26.88 146.204 33.792C146.204 40.704 145.692 46.368 144.668 50.784C143.644 55.136 142.204 58.56 140.348 61.056C138.492 63.552 136.22 65.28 133.532 66.24C130.908 67.136 127.932 67.584 124.604 67.584C121.276 67.584 118.268 67.136 115.58 66.24C112.956 65.28 110.684 63.552 108.764 61.056C106.908 58.56 105.468 55.136 104.444 50.784C103.484 46.368 103.004 40.704 103.004 33.792Z"
                            fill="white" />
                          <path
                            class="number--mask"
                            d="M141.775 -8.47198H225.775L268.775 78.528H184.775L141.775 -8.47198Z"
                            fill="#FDF050" />
                        </g>
                      </svg>
                    </template>
                  </div> -->
                  <p
                    class="blueheadline"
                    v-html="$t('home-modal.column-1.subheadline')"></p>
                  <div
                    class="description"
                    v-html="$t('home-modal.column-1.content')"></div>
                </div>
                <div class="home--modal__content-card--cta">
                  <a class="cta" href="/scalability/configuration-options">{{
                    $t('buttons.learn-more')
                  }}</a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="home--modal__content-card">
                <div class="home--modal__content-card--text">
                  <p class="eyebrow">
                    {{ $t('home-modal.column-2.eyebrow') }}
                  </p>
                  <!-- <div class="number">
                    <span class="animated-gsm js-animated-gsm">320</span>
                  </div> -->
                  <p
                    class="blueheadline"
                    v-html="$t('home-modal.column-2.subheadline')"></p>
                  <div
                    class="description"
                    v-html="$t('home-modal.column-2.content')"></div>
                </div>
                <div class="home--modal__content-card--cta">
                  <a class="cta" href="/scalability/tower-of-production">{{
                    $t('buttons.learn-more')
                  }}</a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="home--modal__content-card">
                <div class="home--modal__content-card--text">
                  <p class="eyebrow">
                    {{ $t('home-modal.column-2.eyebrow') }}
                  </p>
                  <!-- <div class="number--lines-container">
                    <div class="number--lines js-number--lines">
                      <div class="number--lines-internal"></div>
                    </div>
                  </div> -->
                  <!-- <div class="number">
                    <span class="animated-gsm js-animated-gsm">320</span>
                  </div> -->
                  <p
                    class="blueheadline"
                    v-html="$t('home-modal.column-3.subheadline')"></p>
                  <div
                    class="description"
                    v-html="$t('home-modal.column-3.content')"></div>
                </div>
                <div class="home--modal__content-card--cta">
                  <a class="cta" href="/service-support?section=siteprep&sl=4">{{
                    $t('buttons.learn-more')
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="home--modal__content-printer">
            <div class="content-printer-img">
              <img src="images/intro/a2200-1-dryer-zone-with-passive-web-cooling-v1.jpg" alt="a2200-1-dryer-zone-with-passive-web-cooling" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import $ from 'jquery';
import { gsap } from 'gsap';
import { basilModal } from '@/common/cookies';

import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'HomeModal',
  components: {
    Modal
  },
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    const gsm = document.querySelector('.js-animated-gsm');
    const pt = document.querySelector('.js-animated-pt');
    const lines = document.querySelector('.js-number--lines');

    gsap.from(gsm, {
      textContent: 300,
      duration: 6,
      repeatDelay: 6,
      snap: { textContent: 1 },
      repeat: -1,
      ease: 'power4.out',
      stagger: {
        each: 1.0
      }
    });

    gsap.from(pt, {
      textContent: 14,
      duration: 6,
      repeatDelay: 6,
      snap: { textContent: 1 },
      repeat: -1,
      ease: 'power4.inOut',
      stagger: {
        each: 1.0
      }
    });

    gsap.to(lines, {
      duration: 6,
      repeatDelay: 6,
      width: '100%',
      repeat: -1,
      ease: 'power4.out'
    });

    if (!basilModal.get('closedModal')) {
      setTimeout(() => {
        $('.js-modal-home-intro').modal('show');
      }, 2000);
    }

    $('.js-modal-home-intro').on('hidden.bs.modal', () => {
      basilModal.set('closedModal', true);
    });
  },
  beforeUnmount() {
    basilModal.set('closedModal', true);
  },
  watch: {
    // eslint-disable-next-line
    '$i18n.locale': function () {
      setTimeout(() => {
        $('.js-modal-home-intro').modal('show');
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/home-modal.scss';
</style>
