<template>
  <div class="sidebar-controls">
    <div
      v-for="(item, index) in enhancedItems"
      :key="index"
      :class="[
        'sidebar-control',
        'ra-gtm-event',
        {
          'active': active.label === item.label,
          'ischildren': item.isChildren || item.isParent,
          'fakeparent': item.isFakeParent,
          'fakeselection': item.fakeselection
        }
      ]"
      :data-gtm-section="parent"
      :data-gtm-subsection="subsection"
      :data-gtm-label="item.shareLabel"
      @click="handleClick(item, index, $event)">
      <span>
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
import { defaultMixins } from '@/common/mixins';

export default {
  name: 'Sidenav',
  mixins: [defaultMixins],
  props: {
    active: Object,
    items: Array,
    parent: String,
    subsection: String
  },
  emits: ['change-subsection'],
  computed: {
    enhancedItems() {
      const result = [];
      this.items.forEach((item) => {
        // Add a fake parent above each isParent item
        if (item.isParent) {
          result.push({
            ...item,
            label: `${item.fakeParentName}`,
            isFakeParent: true, // Custom property to identify fake parents
            isParent: false // Ensure it's treated separately from actual parents
          });
        }
        result.push(item); // Add the actual item
      });
      return result;
    }
  },
  methods: {
    handleClick(item, index, event) {
      this.$emit('change-subsection', item);
      this.clickTracked(event);
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/components/sideNav.scss';
</style>
